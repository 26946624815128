/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  //border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
    content: url("/assets/images/logo.png");
	display: inline-block;
	position: relative;
	//width: 37px;
	top: 5px;
    float: left;
    margin-bottom: 0;
}

//.site-title {
//  @include relative-font-size(1.625);
//  content: url("/assets/images/logo.png");
//  font-weight: 300;
//  line-height: $base-line-height * $base-font-size * 2.25;
//  letter-spacing: -1px;
//  margin-bottom: 0;
//  float: left;
//
//  &,
//  &:visited {
//    color: $grey-color-dark;
//  }
//}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.copyright {
	text-align: left;
	font-size: 11pt;
}

//.footer-heading {
//  @include relative-font-size(1.125);
//  margin-bottom: $spacing-unit / 2;

.footer-heading {
    //content: url("/assets/images/logo.png");
	display: inline-block;
	position: relative;
	width: 37px;
	//top: 5px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));

/*  > a {
 *   content: url("/assets/images/logo.png");
 *     position: relative;
 *	width: 37px;
 *	//top: 5px;
 *   margin-bottom: $spacing-unit / 2;
}*/


}

.footer-col-2 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
  text-align: right;
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  /* padding: $spacing-unit 0; */
  padding: 80px 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

hr.separator {
	margin-top: 10px;
	margin-bottom: 70px;
	max-width: 50%;
	border: 0;
    height: 1px;
   	/* background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)); */
}

.footnotes {
    margin-top: 3em;
    font-size: 14px;
}
.footnotes hr { margin-bottom: 1em; }

.footnotes a[rev=footnote] { text-decoration: none; }

/**
 * Pagination
 */
.pagination {
	margin-bottom: 0em;
}

.previous-page {
	float: left;
	display: inline;
}

.next-page {
    float: right;
    display: inline;
}

.post-navigation {
	margin-bottom: 7em;
}

.post-navigation {
	margin-bottom: 7em;
}

.previous-post {
	float: left;
	display: inline;
}

.next-post {
	float: right;
	display: inline;
}

/*
 * Overrides for Dark Mode
 * @media (prefers-color-scheme: dark) {
 * 	body {
 *         color: white;
 *         background: #171717;
 *     }
 *     #header {
 * 		background-color: #171717;
 * 	}
 * 	h3 a:link,
 * 	h3 a:visited {
 * 		color: white;
 * 	}
 * 	blockquote {
 * 		color:#909090;
 * 	}
 * }
 */
